<template>
    <div class="terms full-width" v-if="translates && translates[langUrl]">
        <div
            class="terms__main-image">
            <div class="terms__main-image__opacity"></div>
            <div class="terms__main-image__container">
                <!-- <div v-if="!isDesktop" class="main-page__main-image__container__filter">
                    <v-text-field
                        label="Направление"
                    />
                </div> -->
                <div class="terms__main-image__container__text">Privacy Policy</div>
                <!-- <div class="terms__main-image__container__comment">6 простых шагов!</div> -->
                <!-- <div v-if="pageTemplate.main.links" class="main-page__main-image__container__directions">
                    <template v-for="(link, index) in pageTemplate.main.links">
                        <div
                            :key="`main-links-${index}`"
                            class="terms__main-image__container__direction"
                            @click="$router.push(link.link)">{{ link.title }}</div>
                    </template>
                </div> -->
            </div>
        </div>
        <div class="terms__text__container">
            <div class="terms__text">
                <div class="terms__text__updated">Last updated Sept 21, 2022</div>
                <h2>GetAdventures.co</h2>
                <h2>Privacy Policy</h2>

                <p>These Privacy Policy (these “Privacy Policy”) explains how Go-Peaks, Inc. a California corporation (“Company”, “GetAdventures”, “we”, “us”, “our”) (“you”, “your”, or “user”) may gather, collect, record, hold, distribute, share, disclose or otherwise use any information or data about any user (“User” or “You” or “Your”) of the Company’s website at <span>getadventures.co</span> (the “<span>Website</span>” or “<span>Company Website</span>”) or through any other site, program, activity, technology platform, technology application, or service offered by Company.</p>
                <p>Please read this Privacy Policy which explains how we use and protect your personal information. Our Privacy Policy covers all the platforms through which you choose to interact with us, including our mobile-enabled and social media sites. By giving us your personal information, you agree to the transfer, storing or processing of your data in accordance with this policy.</p>
                <p>By making a booking or submitting any personal information to us, you are agreeing to us handling your personal information as set out below. If you do not agree to us using your personal information in these ways, unfortunately we can’t process your booking and you shouldn’t use our sites.</p>
                <p>At times, we do make changes to this Policy, these changes are effective immediately, so please visit these pages regularly to keep up to date with any changes we’ve made. You acknowledge and agree that it is your responsibility to review this site and this Privacy Policy periodically, and be aware of any modifications.</p>
                <h4>What kind of personal information do we collect?</h4>
                <p>When you book through us, you need to provide certain information such as your name, address, e-mail, phone number and payment information. If you happen to fill in the contact page but for some reason don’t complete the booking, you will still be considered to have provided us with your information.</p>
                <p>If you contact us, whether by phone, e-mail, or another method, you’ll be asked to provide certain information. This is likely to include your name, address, e-mail address and phone number.</p>
                <p>We need this information so that we can respond to you appropriately.</p>
                <p>There are also other instances when you’ll provide us with information, for example if you register to manage your booking via our website, enter one of our competitions, or complete a survey.</p>
                <h4>Information you give to us about others in your party</h4>
                <p>If you’re booking with others or for someone else, you will have to give us their information as well, for example their name and date of birth. It is your responsibility to ensure that they are aware that you have done so, and that they accept how we use and process their information as well as yours.</p>
                <h4>What information do we collect automatically?</h4>
                <p>When you visit our Website, we automatically collect certain information such as the device IP address, the date and time you visited, the hardware, software or particular browser used and information about your visit, including any pages you viewed and interacted with. We do this automatically, even when you don’t make a booking.</p>
                <p>If you’re using a mobile device to reach us, we keep data that identifies your device, location details and any specific settings. <span>Information we get from others</span></p>
                <p>We might also get information about you from other sources, such as your Service Providers, affiliates and other third parties like credit reference agencies, payment processors, search engines, or social media sites. We may use this information with other information you provide to complete or process your booking. We will notify you when we receive information about you from them and the purposes for which we intend to use that information.</p>
                <h4>What do we do with your personal information?</h4>
                <p>Your personal information could be used in the following ways: Processing your bookings: We use your personal information to ensure the services you’ve selected, which may include flights, hotels, transport, insurance, and other ancillary products are provided to you. We need certain information about you and your party so we can fulfill the contractual obligations when you make a booking.</p>
                <p><i>User Accounts:</i> The information that you provide when you set up an account allows us to provide you with additional services including the ability to manage your bookings, personal settings, and access to special offers.</p>
                <p><i>To communicate with you about your booking:</i> We’ll need to contact you using the email or telephone number you provided to give you information relevant to your arrangements. For example, to confirm your booking, notification of any changes, security alerts, administrative messages about your arrangements, and reminders.</p>
                <p><i>Customer Services:</i> You can contact us at any time, whether this is a general query, a question about your booking, or to report an issue. You can do this through our website, by telephone or email. We’ll use the information that you’ve given us to answer any questions and respond to your query or other queries you might have in the future. If you do contact us by telephone, your call may be recorded and reviewed for quality and training purposes. Recordings are only kept for a limited period and then automatically deleted unless we have a legitimate interest in keeping them for a longer period.</p>
                <p><i>Personalization of your experience:</i> We sometimes use your data to enable us to show you the most relevant products and services we think you’ll be interested in, such as the best deals and special offers for destinations you like or have been to previously.</p>
                <p><i>Marketing:</i> Where you make a booking, or in any other case, if you have opted-in or signed-up, we may contact you with information about other products and services that we offer that are similar to those that you have already purchased or inquired about, and we think may interest you based on your personal information. Don’t worry, you can opt-out of these emails at any time.</p>
                <p>When you book or register with us, we will ask you if you would like to receive marketing communications. You can change your marketing preferences online, over the phone, using the “unsubscribe” link in our emails, replying STOP to the short code in our marketing text messages or by writing to us at any time. Of course, the choice is entirely yours, but if you say you do not want to receive marketing information from us you will miss out on receiving great offers and promotions that may be of interest to you.</p>
                <p>You may still receive service-related communications from us. For example, confirming bookings you make with us and providing important information about the use of our products and/or services.</p>
                <p><i>Promotional Activities:</i> If you take part in any of our promotions (such as surveys or competitions) we will need to use the information you provide to run these promotions.</p>
                <p><i>Improving our services:</i> Sometimes we might use your data to help us improve the quality and functionality of our services. This could include eliminating bugs in our system, data analysis, testing, and statistical and survey purposes, all of which helps us improve the service we offer.</p>
                <p><i>Security:</i> We may use your data as part of efforts to keep our sites (and the information you provide to us) safe and secure, and to prevent fraud or unauthorized access/use of our sites. Fraudulent Claims: We may use your personal information to prevent, detect, or defend any claims/actions we believe to be potentially fraudulent or detrimental to our legitimate interests. Legal and regulatory compliance: We might need to use your information in relation to any legal disputes, regulatory, compliance or criminal investigations, or if necessary, to enforce our rights.</p>
                <h4>Legal Basis for Processing Personal Data</h4>
                <p>When we process your information as described above, we will be relying on one or more of the following legal rights: Performance of a contract: We use your information to carry out the contract that you have with us and your Service Providers. We’ll use the information that you provide us when making a booking to conclude and process the reservations with the relevant third-party Service Providers.</p>
                <p><i>Consent:</i> You may, through your action(s) of providing us your details, consent to us sending you marketing and promotional material. If you do kindly give us this consent, we’ll rely on this consent when contacting you. You can always withdraw your consent at any time by simply unsubscribing.</p>
                <p><i>Legitimate interest:</i> We may use your information for our own legitimate interest, for example to provide you with the most relevant content on our site or to promote new services and for certain administrative and legal purposes. If you fail to complete a purchase, we may send you some follow-up emails to try and help you, or seek your feedback or offer you alternative products related to your selection.</p>
                <p><i>Business transaction:</i> We may use your data in connection with a business transaction such as the sale of a part or the whole of our business.</p>
                <p><i>To Comply with a legal obligation:</i> We may share your personal data with regulatory authorities such as immigration, border control, security, anti-terrorism, and any other legitimate legal obligation as required.</p>
                <p><i>To protect your vital interests or those of another individual:</i> We may share your information in the case of an emergency. For example, with insurance companies or medical personnel.</p>
                <h4>How do we store your data?</h4>
                <p>Any information that you provide is stored on secure servers and all payment transactions are encrypted. Only authorized personnel are permitted to access your information in the course of their work with us. Our data security protections meet the “reasonable security requirements” of the New York “Shield Act”. While we do our best to protect your information, no information transferred over the internet or stored electronically can be guaranteed to be completely secure and you provide your information to us at your own risk.</p>
                <p>We’ll only retain your information for a reasonable period, or for so long as either you or the law allows.</p>
                <p>When you pay for your tour, we don’t store your card details – instead we use an encrypted authorization ‘token’ issued by your payment provider to collect the payments.</p>
                <h4>For Residents of the European Union</h4>
                <p>Due to the nature of our business and the arrangements you have chosen, it may be necessary for your data to be transferred to, and stored at, a destination outside the European Economic Area ("EEA") and may not be subject to the same level of data protection controls as within the EEA. We will take all reasonable steps to ensure that your data is handled securely.</p>
                <h4>When do we share your data with third parties?</h4>
                <p>In certain circumstances we may share the information you provide to us with organizations outside of Company. The third parties who we might share your data with include:</p>
                <p><i>Service Providers:</i> If you make a booking, we’ll have to share your booking data with the third parties, such as hoteliers, airlines, insurers, and transfer providers to complete your booking contract. The data shared may include your name and the names and ages of any guests travelling with you, any preferences, or special requests you told us about when you made your booking and your contact details. If you contact us with a query about your booking, we may pass this on to our providers if they are needed to answer you.</p>
                <p><i>Third party support services:</i> These includes anyone who provides support services or functions on our behalf, including payment card processing, business analytics, review agencies, advertising, and customer support services. This may be to send you marketing material on our behalf or to collate information that can be analyzed to improve our service. They have access to and may collect information only as required to perform their agreed functions and are not permitted to share or use information for any other purpose.</p>
                <p><i>Payment Processors and other financial institutions:</i> When you make a payment on your booking, we use a third-party payment processor to take your payment. If you request a chargeback on your booking, it may be necessary for us to share certain details with the payment processor and the relevant financial institution, so they can manage the chargeback. This may include a copy of your booking confirmation, financial details and the IP address that was used to make your booking.</p>
                <p><i>Referring websites:</i> If you were referred to our site via another webpage, we may share some information about you with that referring website so that they can contact you about their products and services, which may include Google Analytics. Business Affiliates: We may share your data with our business affiliates with whom we offer relevant joint services. This may include other agencies and advertising networks. We do this to maximize the services that we provide you and so they may contact you directly about their relevant products and services that may interest you. You can unsubscribe from these emails at any time.</p>
                <p><i>Law enforcement authorities:</i> We will disclose personal information insofar as it is required by law or where we believe it is necessary for the prevention, detection, or prosecution of potentially criminal acts, including for fraud, immigration, customs, security, or anti-terrorism purposes.</p>
                <p>Please note that while we have procedures and security features to protect your information from access by unauthorized persons and against unlawful processing, accidental loss, destruction, and damage, we don’t control the privacy practices of any of these third-parties or your Service Providers and we would encourage you to review their own privacy policies where appropriate.</p>
                <p>Furthermore, any information that you provide directly to your Service Providers or other suppliers is not covered under this Privacy Policy and we would urge to check their own privacy policies before providing any personal information.</p>
                <h4>What rights do you have in relation to your personal information?</h4>
                <p>You have the following rights in relation to the personal information that we hold about you:</p>
                <p>You may request a copy of the personal information we are keeping about you by submitting a request to us at the below address.</p>
                <p>You may ask that we correct any inaccuracies in the data, ask us to transfer it to another party or provide it to you for transfer it to another party yourself.</p>
                <p>You can ask us not to process your personal information for marketing purposes at any time by sending a request to the below address or clicking ‘unsubscribe’ on any of the emails that we send to you. Alternatively, you can also email EMAIL HERE and it will be done for you. We will let you know if we intend to use your data for marketing purposes.</p>
                <p>You have the right to request that we delete all personal information that we hold about you. If there is no other reason beside you providing your consent for us to hold your data, we will delete it upon receiving your request.</p>
                <p>We <span>may not</span> delete your personal information if one of the following exceptions exists:</p>
                <ul>
                    <li>Information is necessary to complete the transaction;</li>
                    <li>Information is necessary to detect security incidents;</li>
                    <li>Information is necessary to protect against deceptive, fraudulent, or illegal activity;</li>
                    <li>Information is necessary to identify and repair errors;</li>
                    <li>Information is necessary to promote free speech;</li>
                    <li>Information is necessary for scientific, historical, or statistical research in the public interest;</li>
                    <li>Information is necessary for internal uses of the company if those uses are reasonably expected by consumers;</li>
                    <li>Information is necessary to comply with a legal obligation; </li>
                    <li>Information is used internally in a manner that is compatible with the context of the collection.</li>
                </ul>
                <p>If you would like to exercise any of your above rights, please contact us at EMAIL HERE writing ‘Data Subject Access Request’ in the subject line. We will deal with any requests we receive in accordance with the relevant Data Protection laws. <span>What about data that is collected through a mobile device?</span> Mobile devices can be used to access our services, as well as versions of our regular website. These mobile sites work in a similar way to our main website. Sometimes we make use of something known as cross-device tracking, which allows us to track user behavior across different devices. We use this to improve the service that we provide you and for marketing activities, so advertisements shown to you on other websites may be offered based on your activities on linked devices.</p>
                <h4>Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)</h4>
                <p>We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>
                <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>
                <h4>Tracking and Cookies Data</h4>
                <p>We use cookies and similar tracking technologies to track the activity on our Website and we hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, some portions of our services may not perform properly.</p>
                <h4>Google Analytics</h4>
                <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>
                <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.</p>
                <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en">Google Privicy Policy.</a></p>
                <h4>How do we treat personal information of children?</h4>
                <p>You are only allowed to book through our site if you are over 18 years of age. We only process information about children with the consent of the parents or legal guardians.</p>
                <h4>Links to other sites</h4>
                <p>Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>
                <h4>Who exactly is responsible for processing personal information that is collected under the policy?</h4>
                <p>We control the processing of data over our sites, in legal terms, this means that Company is the Data Controller for the purposes of EU Data Protection laws.</p>
                <h4>How can you contact us?</h4>
                <p>If you have any questions about this Privacy Policy or wish to exercise any of your rights under it, please contact us at: {{ pageTemplate.email[lang] }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';

    export default {
        name: 'PrivacyPolicy',
        metaInfo() {
            return {
                title: this.lang === 'ru' ? 'Политика конфидециальности' : 'Privacy Policy'
            }
        },
        components: {
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            langUrl: '/about'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
            ...mapState('pageTemplates', {
                pageTemplate: state => state.footer.contacts
            }),
        },
        methods: {
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
        },
    };
</script>

<style lang="scss">
.terms {
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    // display: flex;
    // flex-direction: column;
    &__main-image {
        position: relative;
        width: 100%;
        height: 440px;
        background: url(../assets/about/About_1.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
        }
        &__opacity {
            position: absolute;
            width: 100%;
            height: 100%;
            // background: #543919;
            background: #000;
            opacity: 0.5;
        }
        &__container {
        position: absolute;
        width: 100%;
        height: 440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        margin-bottom: 40px;
            @media all and (max-width: 768px) {
                height: 356px;
            }
            &__text {
                font-weight: bold;
                font-size: 56px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                }
            }
            &__comment {
                font-size: 24px;
                margin-top: 8px;
                line-height: 150%;
                letter-spacing: -0.02em;
                @media all and (max-width: 768px) {
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }
    &__text {
        max-width: 1400px;
        margin: 0 auto;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        &__container {
            display: flex;
        }
        > h2 {
            font-size: 30px;
            margin-bottom: 40px;
            &:first-of-type {
                margin-bottom: 0;
            }
        }
        > h3 {
            font-size: 20px;
            font-weight: bold;
            align-self: center;
            &:last-of-type {
                margin-bottom: 40px;
            }
        }
        > h4 {
            font-size: 16px;
        }
        > h5 {
            font-size: 14px;
            font-style: italic;
        }
        > h6 {
            font-size: 14px;
            text-decoration: underline;
        }
        > p {
            font-size: 14px;
            margin-bottom: 20px;
            > span {
                font-weight: bold;
            }
        }
        > table {
            max-width: fit-content;
            align-self: center;
            border: 1px #000 solid;
            margin-bottom: 20px;
            td {
                padding: 0 10px;
                border: 1px #000 solid;
            }
        }
    }
}

</style>